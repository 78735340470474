import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignIn from "../SignIn/SignIn";
import "./Navbar.css";
import Colors from "../Colors/Colors";
import SignUpProcess from "../SignUpProcess/SignUpProcess";
import SignUpProcessOfCustomer from "../SignUpProcessOfCustomer/SignUpProcessOfCustomer";
import moment from "moment";
import Loader from "react-loader-spinner";
import { DebounceInput } from "react-debounce-input";
import { getRefFromQuery } from "../CustomFunctions/CustomFunctions";
import { RedirectTo } from "../Redux/reducers/redirect/redirectSlice";

import {
  setTooltipFalse,
  setTooltipTrue,
} from "../Redux/reducers/ToolTip/ToolTip";
import { enableScroll, disableScroll } from "../Redux/reducers/Scroll/Scroll";
import { Mixpanel } from "../Mixpanel";
import { LogoName } from "../settings";
import { useDispatch, useSelector } from "react-redux";
import {
  zIndexNormal,
  zIndexRaise,
} from "../Redux/reducers/searchBar/searchBar";
import {
  setLogin,
  setCustomerFalse,
} from "../Redux/reducers/customer/customerSlice";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";
import {
  setProfessionalsToTrue,
  setProfessionalsToFalse,
} from "../Redux/reducers/professional/professionalSlice";
import { FreeCallNotCompleted } from "../Redux/reducers/freeCall/freeCallSlice";
import { setpebtotrue, setpebtofalse } from "../Redux/reducers/peb/pebSlice";
import { margin } from "@mui/system";

const Navbar = (props) => {
  let dispatch = useDispatch();
  const zIndex = useSelector((state) => state.searchBar.zIndex);
  let showPaymentModal = useSelector((state) => state.payment.showPaymentModal);
  let charity = useSelector((state) => state.charity.isCharity);
  let showAnonymousInfo = useSelector(
    (state) => state.anonymous.showAnonymousInfo
  );

  const isAnonymousModal = useSelector(
    (state) => state.anonymous.toggleAnonymous
  );
  const showReviews = useSelector((state) => state.review.showReviews);
  let isModal = useSelector((state) => state.freeCall.isModal);
  let voucher = useSelector((state) => state.voucher.isVoucher);
  let login = useSelector((state) => state.customer.isLogin);
  const scrollingEnabled = useSelector(
    (state) => state.scroll.scrollingEnabled
  );

  let queryString = useLocation();
  getRefFromQuery(queryString);
  const [isLoading, setLoading] = useState(false);
  const [showDot, setShowDot] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [signup, setSignup] = useState(false);
  const [user, setUser] = useState({});
  const [more, setMore] = useState(false);
  const [about, setAbout] = useState(false);
  const [browse, setBrowse] = useState(false);
  const [becomeAMentor, setBecomeAMentor] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [notificationSeleted, setNotificationSelected] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);

  let professionalSignUpFlow = useSelector(
    (state) => state.professional.isProfessionalFlow
  );
  let inputBackDrop = useSelector((state) => state.PEBtoggle.peb);
  const [customerSignUpFlow, setCustomerSignUpFlow] = useState(
    queryString.pathname === "/signup" ? true : false
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawer, setDrawer] = useState(false);
  const [searchMentorLoading, setSearchMentorLoading] = useState(false);

  // const [inputBackDrop, setInputBackDrop] = useState(false);
  const [industry, setIndustry] = useState([]);

  let type = localStorage.getItem("cp_web_token")
    ? JSON.parse(localStorage.getItem("type"))
    : "customer";

  const handleToolTip = () => {
    dispatch(setTooltipFalse());
  };
  const [searchDropDown, setSearchDropDown] = useState({
    filter_: "",
    data_: [],
  });

  //#region disabling scroll
  useEffect(() => {
    if (!scrollingEnabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [scrollingEnabled]);
  //#endregion

  //#region Enabling/Disabling scroll with navbar open/close
  const DisableNavbarScroll = () => {
    console.log("enable called");
    dispatch(setTooltipFalse());
    //# this code comment reason mobile view page not scroll
    //# dispatch(disableScroll());
  };

  const EnableNavbarScroll = () => {
    console.log("disable called");
    dispatch(enableScroll());
    dispatch(setTooltipTrue());
  };
  //#endregion

  const [clicked, setClicked] = useState(null);
  const toggleAccordian = (index) => {
    if (clicked === index) {
      return setClicked(index);
    }
    setClicked(index);
  };
  let navigate = useNavigate();

  const toggling = () => {
    setIsOpen(true);
  };
  const toggleLogin = () => {
    Mixpanel.track("Press login button web");
    // dispatch({ type: 'setLogin' })
    dispatch(setLogin());
    // dispatch({ type: 'setBannerToTrue' })
    dispatch(RedirectTo({ url: window.location.pathname }));
    dispatch(setBannerTrue());
    // setLogin(!login);
  };
  const getAllIndustries = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/industry`)
      .then((response) => {
        response.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        setIndustry(response.data);
      })
      .catch((e) => {
        console.log("error in industry api");
      });
  };
  const toggleProfessionalSignUpFlow = () => {
    if (!professionalSignUpFlow) {
      dispatch(setProfessionalsToTrue());
    } else {
      dispatch(setProfessionalsToFalse());
    }
  };

  const toggleCustomerSignUpFlow = () => {
    setCustomerSignUpFlow(false);
    setSignup(false);
    dispatch(setProfessionalsToFalse());
    Mixpanel.track("Press Signup button web");
    dispatch(setCustomerFalse());
  };

  const deleteNotifications = () => {
    setLoading(true);
    let data = JSON.stringify({
      notification_ids: notificationSeleted,
    });
    let config = {
      method: "delete",
      url: `${
        process.env.REACT_APP_SERVER_PATH
      }/deleteUserNotifications/${JSON.parse(localStorage.getItem("id"))}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setEdit(false);
        getUnreadNotifications();
        setLoading(false);
        // setShow(false)
      })
      .catch((error) => {
        console.log("Not Deleted");
      });
  };

  const allNotificationRead = () => {
    setLoading(true);
    let config = {
      method: "patch",
      url: `${
        process.env.REACT_APP_SERVER_PATH
      }/updateUserNotificationsStatus/${JSON.parse(
        localStorage.getItem("id")
      )}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
      },
    };

    axios(config)
      .then((response) => {
        setEdit(false);
        getUnreadNotifications();
        setLoading(false);
      })
      .catch((error) => {
        console.log("Not All Read");
      });
  };

  const markRead = () => {
    setLoading(true);
    let data = JSON.stringify({
      notification_id: notificationSeleted,
    });
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_PATH}/notifications`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setEdit(false);
        getUnreadNotifications();
        setLoading(false);
      })
      .catch((error) => {
        console.log("Not Deleted");
      });
  };
  const handleNotifications = (id) => {
    if (notificationSeleted.includes(id)) {
      // Remove ID
      const notificationSeletedCopy = [...notificationSeleted];
      const itemIndex = notificationSeletedCopy.indexOf(id);
      notificationSeletedCopy.splice(itemIndex, 1);
      setNotificationSelected(notificationSeletedCopy);
    } else {
      // Add ID
      // selectedIndustry.push(id)
      setNotificationSelected([...notificationSeleted, id]);
    }
  };

  const getUnreadNotifications = () => {
    // let id = JSON.parse(localStorage.getItem("id"));
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/notifications_history/` +
          localStorage.getItem("id") +
          `?limit=20`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        console.log("Error in getting Unread Notifications");
      });
  };

  const getReadNotifications = () => {
    // let id = JSON.parse(localStorage.getItem("id"));
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/notifications_history/` +
          localStorage.getItem("id") +
          `?status=1&limit=20`
      )
      .then((response) => {})
      .catch(() => {
        console.log("Error in getting Notifications");
      });
  };

  const ShowDotIcon = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_SERVER_PATH
        }/notifications_unread/${localStorage.getItem("id")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        setShowDot(true);
      })
      .catch((e) => {
        setShowDot(false);
        // console.log("Error is from getting unread notification")
      });
  };

  const handleChanger = (event) => {
    toggling();
    setSearchQuery(event.target.value);
    setSearchMentorLoading(true);
    if (event.target.value === "") {
      setIsOpen(false);
      setSearchMentorLoading(false);
      return;
    }
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/professionals?search=${event.target.value}`
      )
      .then((response) => {
        console.log("API Call Successful");
        let arrayofObj = response.data;
        Mixpanel.track(`web: Search for ${event.target.value}`, {
          "search value": event.target.value,
        });
        let first_lastName = arrayofObj.map((item) => {
          return {
            user_id: item.user_id,
            fname: item.User.first_name,
            lname: item.User.last_name,
            job_title: item.job_title,
            profilePhoto: item.User?.profile_photo,
          };
        });
        setSearchDropDown({ ...searchDropDown, data_: first_lastName });
        setSearchMentorLoading(false);
      })
      .catch(() => {
        console.log("Error while Searching");
        setUser({});
      });
  };

  if (login) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  if (signup) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const url = useSelector((state) => state.redirect.url);
  const result = url.includes("dashboard");
  useEffect(() => {
    // let id = localStorage.getItem("id") ? JSON.parse(localStorage.getItem("id")):null;
    axios
      .get(
        process.env.REACT_APP_SERVER_PATH +
          `/users/` +
          localStorage.getItem("id")
      )
      .then((response) => {
        setUser(response.data);
      })
      .catch(() => {
        setUser({});
      });
    getAllIndustries();
    getReadNotifications();
    getUnreadNotifications();
    ShowDotIcon();
    let isAuthenticated = localStorage.getItem("cp_web_token");

    if (!isAuthenticated && result) {
      toggleLogin();
    }
  }, []);

  const [isSearchDropdown, setIsSearchDropdown] = useState(false);
  const toggleSearchDropdown = () => {
    setIsSearchDropdown(!isSearchDropdown);
    setShowOverlay(!showOverlay);
  };
  const closeSearchDropdown = () => {
    setIsSearchDropdown(false);
    setShowOverlay(false);
  };

  //#region Dropdown for mobile search
  const dropdownMobileProfile = searchDropDown.data_.length ? (
    searchDropDown.data_.map((obj) => {
      return (
        <div
          onClick={() => {
            setIsOpen(false);
            navigate(
              `/profile/${obj.fname.toLowerCase()}-${obj.lname.toLowerCase()}-${
                obj.user_id
              }`
            );
            window.location.reload();
          }}
        >
          <div
            className="flex w-full p-2 border-b cursor-pointer hover:bg-bgGray"
            // style={{ fontFamily: "Cerebri Sans Book" }}
            style={{ fontFamily: "Lato sans-serif" }}
          >
            <div className="w-2/12">
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={obj.profilePhoto ? obj.profilePhoto : " /avatar.png"}
                alt="Hello"
              />
            </div>
            <div className="flex flex-col w-10/12">
              <div className="truncate font-bold ml-5">
                {obj.fname} {obj.lname}
              </div>
              <div className="truncate overflow-x-hidden ml-5">
                {obj.job_title}
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : searchMentorLoading ? null : (
    <div>
      <div
        className="bg-white hover:text-textDark"
        onClick={() => {
          toggling();
        }}
      >
        <div className="flex items-center justify-center">
          <p className="text-lg py-2">No result for "{searchQuery}"</p>
        </div>
      </div>
    </div>
  );
  //#endregion

  const dropdownforProfile = searchDropDown.data_.length ? (
    searchDropDown.data_.map((obj) => {
      return (
        <div
          onClick={() => {
            setIsOpen(false);
            navigate(
              `/profile/${obj.fname.toLowerCase()}-${obj.lname.toLowerCase()}-${
                obj.user_id
              }`
            );
            window.location.reload();
          }}
        >
          <div
            className="flex w-full p-2 border-b cursor-pointer hover:bg-bgGray"
            // style={{ fontFamily: "Cerebri Sans Book" }}
            style={{ fontFamily: "Lato sans-serif" }}
          >
            <div className="w-2/12">
              <img
                className="w-12 h-12 rounded-full object-cover"
                src={obj.profilePhoto ? obj.profilePhoto : " /avatar.png"}
                alt="Hello"
              />
            </div>
            <div className="flex flex-col w-10/12">
              <div className="truncate font-bold">
                {obj.fname} {obj.lname}
              </div>
              <div className="truncate overflow-x-hidden">{obj.job_title}</div>
            </div>
          </div>
        </div>
      );
    })
  ) : searchMentorLoading ? null : (
    <div>
      <div
        className="bg-white hover:text-textDark"
        onClick={() => {
          toggling();
        }}
      >
        <div className="flex items-center justify-center">
          <p className="text-lg py-2">No result for "{searchQuery}"</p>
        </div>
      </div>
      {/* <ListItem className="cursor-pointer hover:text-textDark" onClick={() => {
            toggling()
        }}>
                <div className='flex items-center justify-center'>
                    <p className='text-lg py-2'>No result for "{searchQuery}"</p>
                </div>
        </ListItem> */}
    </div>
  );
  const toggleInputBackDrop = () => {
    dispatch(setpebtofalse());
    setIsOpen(false);
    setSearchQuery("");
  };
  return (
    <div className="w-full bg-white">
      {show && (
        <div
          className="w-screen h-screen bg-black opacity-70 md:bg-transparent top-0 left-0 fixed z-20"
          onClick={() => {
            setEdit(false);
            setShow(!show);
            setNotificationSelected([]);
          }}
        ></div>
      )}
      {inputBackDrop && (
        <div
          className="w-screen h-screen bg-black opacity-80 top-0 left-0 fixed z-40"
          onClick={toggleInputBackDrop}
        ></div>
      )}

      {login && (
        <SignIn
          toggle={toggleLogin}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={login}
          customerSignUpFlow={customerSignUpFlow}
          setCustomerSignUpFlow={setCustomerSignUpFlow}
        />
      )}
      {professionalSignUpFlow && (
        <SignUpProcess
          toggle={toggleProfessionalSignUpFlow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={professionalSignUpFlow}
        />
      )}
      {customerSignUpFlow && (
        <SignUpProcessOfCustomer
          toggle={toggleCustomerSignUpFlow}
          overlay="overlay"
          modal="modal"
          modalcontent="modal-content"
          closeModal="close-modal"
          modalValue={customerSignUpFlow}
          customerSignUpFlow={customerSignUpFlow}
          setCustomerSignUpFlow={setCustomerSignUpFlow}
        />
      )}
      <div className="xl:container mx-auto px-2 py-2 flex items-center justify-between w-full md:bg-white">
        {/* logo - start */}
        <div className="flex items-center ">
          <div className="logo-cp">
            <Link to="/">
              <img src={LogoName} alt="logo" className="" />
            </Link>
          </div>

          <div
            className="hidden lg:inline-block mt-1 ml-2"
            onClick={() => {
              navigate(`/mentors`);
            }}
            onMouseEnter={() => {
              setBrowse(true);
            }}
            onMouseLeave={() => {
              setBrowse(false);
              setClicked(null);
            }}
          >
            <p
              className="font-bold text-black hover:bg-bgGray cursor-pointer rounded-xl py-2 px-4 nav-item nav-item-big"
              style={{ fontFamily: "Lato sans-serif" }}
            >
              <div className="flex items-center">
                <p>Find a mentor</p>
                <span className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>
              {browse && (
                <div className="relative">
                  <div
                    className="absolute rounded-2xl border -left-7 py-3 shadow-2xl bg-white z-40"
                    style={{ top: "8px" }}
                  >
                    <div className="flex">
                      <div className="w-64 border-r">
                        {industry.map((i, index) => {
                          return (
                            <div className="flex">
                              <div
                                className={`${
                                  clicked === index ? "bg-bgGray" : ""
                                } px-4 hover:bg-bgGray w-72`}
                                onMouseEnter={() => {
                                  toggleAccordian(index);
                                }}
                              >
                                <div className=" flex justify-between items-center">
                                  <button
                                    className="py-1 text-left w-full flex items-center nav-item"
                                    onClick={() => {
                                      navigate(`/mentors/${i.slug}`);
                                      // if (queryString.pathname === `/mentors/${i.slug}`) {
                                      window.location.reload(true);
                                      // }
                                    }}
                                    // style={{ fontSize: "0.8rem" }}
                                  >
                                    {i.name}
                                    {i.name === "Strategy Consulting" ||
                                    i.name === "Performance Coaching" ||
                                    i.name === "Asset Management" ||
                                    i.name === "Tech" ? (
                                      <span className="text-xs ml-2 p-2 text-white bg-bgAppColor rounded-full h-6 flex items-center">
                                        new
                                      </span>
                                    ) : null}
                                  </button>
                                  {i.sub && i.sub.length > 0 && (
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3 w-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 5l7 7-7 7"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {clicked !== null ? (
                        <div>
                          {industry.map((i, index) => {
                            return (
                              <div>
                                <div
                                  className=" px-4 hover:bg-bgGray w-72 hidden"
                                  onMouseEnter={() => {
                                    toggleAccordian(index);
                                  }}
                                  onMouseLeave={() => {
                                    // toggleAccordian(index)
                                  }}
                                >
                                  <button className="py-1  text-left w-full nav-item">
                                    {i.name}
                                  </button>
                                </div>
                                {clicked === index ? (
                                  <div>
                                    {i.sub && i.sub.length > 0 ? (
                                      <div>
                                        {i.sub.map((s) => {
                                          return (
                                            <div className=" px-4 hover:bg-bgGray w-64">
                                              <div>
                                                <button
                                                  className="py-1 text-left w-full text-gray-800 nav-item"
                                                  // style={{ fontSize: "0.8rem" }}
                                                  onClick={() => {
                                                    navigate(
                                                      `/mentors/${s.slug}`,
                                                      {
                                                        state: {
                                                          industryForToggle:
                                                            i.slug,
                                                        },
                                                      }
                                                    );
                                                    window.location.reload(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  {s.name}
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </p>
          </div>

          <Link
            to="/apply-to-become-mentor"
            className="hidden lg:inline-block "
          >
            <p
              className="mt-1 font-bold text-black hover:bg-bgGray rounded-xl p-2 px-4 nav-item"
              style={{ fontFamily: "Lato sans-serif" }}
            >
              Become a mentor
            </p>
          </Link>

          <div
            className="hidden lg:inline-block mt-1"
            onMouseEnter={() => {
              setAbout(true);
            }}
            onMouseLeave={() => {
              setAbout(false);
            }}
          >
            <p
              className="  font-bold text-black hover:bg-bgGray cursor-pointer rounded-xl p-2 pl-3 nav-item"
              style={{ fontFamily: "Lato sans-serif" }}
            >
              <div className="flex items-center">
                <p>About</p>
                <span className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </div>

              {about && (
                <div className="relative">
                  <div
                    className="absolute w-48 rounded-2xl border -left-8 py-3 shadow-2xl bg-white z-40"
                    style={{ top: "8px" }}
                  >
                    <div className="w-full px-4 hover:bg-bgGray">
                      <Link to="/about-us">
                        <button
                          className="py-1 text-left w-full"
                          style={{ fontFamily: "Lato sans-serif" }}
                        >
                          About us
                        </button>
                      </Link>
                    </div>
                    <div className="w-full px-4 hover:bg-bgGray">
                      <Link to="/how-it-works">
                        <button
                          className="py-1 text-left w-full"
                          style={{ fontFamily: "Lato sans-serif" }}
                        >
                          How it works
                        </button>
                      </Link>
                    </div>
                    <div className="w-full px-4 hover:bg-bgGray">
                      <Link to="/what-our-mentor-do">
                        <button
                          className="py-1 text-left w-full"
                          style={{ fontFamily: "Lato sans-serif" }}
                        >
                          What our mentors do
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </p>
          </div>

          {!inputBackDrop && (
            <div className="flex items-center">
              {/* <Link to="/course" className="hidden lg:inline-block ">
                <p
                  className="mt-1 font-bold text-black hover:bg-bgGray rounded-xl p-2 px-4 nav-item"
                  style={{ fontFamily:  'Lato sans-serif' }}
                >
                  Training Courses
                </p>
          </Link> */}
              {/* <Link
                to="/private-equity-bundle"
                className="hidden lg:inline-block "
              >
                <p
                  className="mt-1 font-bold text-black hover:bg-bgGray rounded-xl p-2 px-4 nav-item"
                  style={{ fontFamily: "Lato sans-serif" }}
                >
                  Private Equity Bundle
                </p>
              </Link> */}

              <div
                className="hidden lg:inline-block mt-1"
                onMouseEnter={() => {
                  setMore(true);
                }}
                onMouseLeave={() => {
                  setMore(false);
                }}
              >
                <p
                  className="  font-bold text-black hover:bg-bgGray cursor-pointer rounded-xl p-2 pl-3 nav-item"
                  style={{ fontFamily: "Lato sans-serif" }}
                >
                  <div className="flex items-center">
                    <p>More</p>
                    <span className="ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </span>
                  </div>

                  {more && (
                    <div className="relative">
                      <div
                        className="absolute w-1005 rounded-2xl border -left-8 py-3 shadow-2xl bg-white z-40"
                        style={{ top: "8px" }}
                      >
                        <div className="w-full px-4 hover:bg-bgGray">
                          <Link to="/download">
                            <button
                              className="py-1 text-left w-full"
                              style={{ fontFamily: "Lato sans-serif" }}
                            >
                              Download
                            </button>
                          </Link>
                        </div>
                        <div className="w-full px-4 hover:bg-bgGray">
                          <Link to="/career">
                            <button
                              className="py-1 text-left w-full"
                              style={{ fontFamily: "Lato sans-serif" }}
                            >
                              Careers
                            </button>
                          </Link>
                        </div>
                        <div className="w-full px-4 hover:bg-bgGray">
                          <Link to="/support">
                            <button
                              className="py-1 text-left w-full"
                              style={{ fontFamily: "Lato sans-serif" }}
                            >
                              Support
                            </button>
                          </Link>
                        </div>
                        {type === "customer" && (
                          <div className="w-full px-4 hover:bg-bgGray">
                            <Link to="/invite-user">
                              <button className="py-1 text-left w-full">
                                Get £20 free
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        {/* logo - end */}
        <div className="right flex items-center sm:justify-end md:justify-start ">
          <div
            style={{ fontFamily: "Lato sans-serif" }}
            className={`mb-1 relative ${
              isModal ||
              login ||
              customerSignUpFlow ||
              professionalSignUpFlow ||
              voucher ||
              showReviews ||
              isAnonymousModal ||
              charity ||
              showAnonymousInfo ||
              showPaymentModal
                ? "z-20"
                : zIndex
            } hidden md:flex items-center bg-white h-10 md:mr-3 mt-1 ${
              !inputBackDrop ? "rounded-full" : "rounded-t-3xl"
            } text-sm ${!inputBackDrop ? "border" : ""} border-borderGray `}
          >
            {searchMentorLoading ? (
              <span className="w-2 h-2 flex justify-center items-center ml-4">
                <Loader
                  type="Oval"
                  width={20}
                  height={20}
                  color={Colors.blue}
                />
              </span>
            ) : (
              <Link to={`/mentors/?search=${searchQuery}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-4 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill={Colors.darkGray}
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // navigate(`/mentors/?search=${searchQuery}`);
                let pageUrl = `/mentors/?search=${searchQuery}`;
                window.location.href = pageUrl;
                // window.location.reload(true);
              }}
            >
              <DebounceInput
                // className={`mt-1 sm:w-36 pl-5 focus:outline-none bg-transparent md:w-60 lg:w-96 text-sm text-textDark`}
                className={`mt-1 sm:w-36 ${
                  inputBackDrop
                    ? "lg:w-96 md:w-60 xl:w-64 2xl:w-64"
                    : "lg:w-36 md:w-36 xl:w-64 2xl:w-64"
                } pl-5 focus:outline-none bg-transparent text-sm text-textDark`}
                minLength={1}
                debounceTimeout={1000}
                placeholder="Search..."
                value={searchQuery}
                onBeforeInputCapture={() => {
                  // if (window?.location?.pathname?.includes('checkout')) {
                  //   dispatch(setpebtofalse())
                  // } else {
                  dispatch(setpebtotrue());
                  // }
                }}
                onChange={handleChanger}
                onClick={handleToolTip}
              />

              {/* <input style={{ backgroundColor: 'transparent', color: "black" }} value={searchQuery} placeholder="Search..." className="mt-1 sm:w-36 md:w-96 pl-5 focus:outline-none" onChange={handleChanger} /> */}
            </form>

            {inputBackDrop && (
              <button className="mx-4" onClick={toggleInputBackDrop}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="gray"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
            {isOpen && (
              <div
                className={`absolute w-full top-9 bg-white z-50 overflow-auto overflow-x-hidden ${
                  searchDropDown.data_.length > 6 ? "h-96" : "h-auto"
                } rounded-b-3xl py-6`}
              >
                {dropdownforProfile}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: localStorage.getItem("cp_web_token")
                ? "space-between"
                : "flex-end",
              alignItems: "center",
            }}
          >
            {/* mobile search icon */}
            <button onClick={toggleSearchDropdown} className="md:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-4 "
                viewBox="0 0 20 20"
                fill="gray"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {showOverlay && (
              <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"></div>
            )}
            {isSearchDropdown && (
              <div className="relative md:hidden z-40 flex items-center justify-center">
                <div className="absolute top-6 -right-11 rounded-lg z-30 same-width">
                  <div className="flex flex-row items-center border border-borderGray bg-white h-10 px-1 rounded-lg text-sm">
                    <div className="w-full">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-1 h-5"
                        viewBox="0 0 20 20"
                        fill="gray"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg> */}
                      <form
                        className=""
                        onSubmit={(e) => {
                          e.preventDefault();
                          // navigate(`/mentors/?search=${searchQuery}`);
                          let pageUrl = `/mentors/?search=${searchQuery}`;
                          window.location.href = pageUrl;
                          // window.location.reload(true);
                        }}
                      >
                        <input
                          placeholder="Search.."
                          className="focus:outline-none px-3 w-full"
                          onChange={handleChanger}
                          value={searchQuery}
                        />
                        {isOpen && (
                          <div
                            className={`absolute rounded-lg z-30 top-12 bg-white overflow-auto overflow-x-hidden md:hidden ${
                              searchDropDown.data_.length > 6
                                ? "h-96"
                                : "h-auto"
                            } rounded-b-3xl py-6 px-auto same-width`}
                          >
                            {dropdownMobileProfile}
                          </div>
                        )}
                      </form>
                    </div>
                    <button className="" onClick={closeSearchDropdown}>
                      <svg
                        className="w-5 h-5 mr-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"
                          fill="black"
                        />{" "}
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* end mobile search icon */}

            {/* notifications */}
            {localStorage.getItem("cp_web_token") ? (
              <div
                className={`${
                  isDrawer ? "hidden" : "inline-block"
                } z-30  md:flex cursor-pointer`}
              >
                <span
                  onClick={() => {
                    setShow(!show);
                    setShowDot(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 md:h-8 md:w-8 sm:mr-4 md:mr-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke={Colors.darkGray}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  {showDot && (
                    <div className="absolute">
                      <span className="animate-bounce w-2 h-2 absolute bottom-4 left-4 md:bottom-6 md:left-6 bg-red-600 rounded-full"></span>
                    </div>
                  )}
                </span>
                {show ? (
                  <div
                    className={`z-30 bg-white flex flex-col  border-2 absolute right-0 md:right-36  xl:right-24 top-16 rounded z-80`}
                    style={{ zIndex: "1000" }}
                  >
                    {isLoading ? (
                      <div className="w-96 h-96 flex justify-center items-center">
                        <Loader
                          type="Oval"
                          width={50}
                          height={50}
                          color={Colors.blue}
                        />
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p className="text-lg font-bold text-center mt-2">
                            Notifications
                          </p>
                        </div>
                        <div className="mx-2 flex items-center justify-between py-0 px-2">
                          <button
                            className="text-sm font-bold text-blue-600"
                            onClick={() => setEdit(!edit)}
                          >
                            {edit ? "Done" : "Edit"}
                          </button>
                          <button
                            className="text-sm font-bold text-blue-600"
                            onClick={allNotificationRead}
                          >
                            Mark all as read
                          </button>
                        </div>
                        {edit && (
                          <div className="flex items-center justify-between p-2 absolute rounded bottom-0 bg-white w-full">
                            <button
                              className="text-sm font-bold bg-red-600 text-white rounded py-2 px-4"
                              onClick={deleteNotifications}
                            >
                              Delete
                            </button>
                            <button
                              className="text-sm font-bold text-white bg-blue-600 rounded-lg py-2 px-4"
                              onClick={markRead}
                            >
                              Mark as read
                            </button>
                          </div>
                        )}
                        <div className="w-80 h-80 md:w-96 md:h-96 overflow-auto">
                          {data.length > 0 ? (
                            data.map((notification) => {
                              return (
                                <div
                                  className={`w-full  hover:bg-bgGray  items-center px-4 flex ${
                                    notification.status === "0"
                                      ? "bg-bgGray"
                                      : "bg-white"
                                  } text-black border-b-2 border-borderGray py-3`}
                                >
                                  {edit ? (
                                    <div className="flex items-center justify-center">
                                      <input
                                        type="checkbox"
                                        className="w-5 h-5"
                                        onChange={() =>
                                          handleNotifications(notification.id)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="w-1/12 border border-green-500 rounded-full flex items-center justify-center">
                                      <img
                                        src="/calendar.png"
                                        className="p-1 object-cover"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="w-full ml-4 flex flex-col"
                                    onClick={() => {
                                      if (
                                        notification.resource_type === "booking"
                                      ) {
                                        axios
                                          .patch(
                                            `${process.env.REACT_APP_SERVER_PATH}/notifications/${notification.id}`
                                          )
                                          .then((response) => {
                                            getUnreadNotifications();
                                            navigate(
                                              `/dashboard/bookings/${notification.resource_id}`
                                            );
                                            ShowDotIcon();
                                            setShow(false);
                                          })
                                          .catch((e) => {
                                            console.log(
                                              "Error while changing status of Notification"
                                            );
                                          });
                                      } else if (
                                        notification.resource_type ===
                                        "transaction"
                                      ) {
                                        axios
                                          .patch(
                                            `${process.env.REACT_APP_SERVER_PATH}/notifications/${notification.id}`
                                          )
                                          .then((response) => {
                                            axios
                                              .get(
                                                `${process.env.REACT_APP_SERVER_PATH}/get_transaction_by_id_customer/${notification.resource_id}`,
                                                {
                                                  headers: {
                                                    "Content-Type":
                                                      "application/json",
                                                    Authorization:
                                                      "Bearer " +
                                                      localStorage.getItem(
                                                        "cp_web_token"
                                                      ),
                                                  },
                                                }
                                              )
                                              .then((response) => {
                                                getUnreadNotifications();
                                                // navigate(`/dashboard/bookings/${response.data.message.booking_id}`);
                                                ShowDotIcon();
                                                // setShow(false);
                                              })
                                              .catch((e) => {
                                                console.log(
                                                  "Error while changing status of Notification 1"
                                                );
                                              });
                                          })
                                          .catch((e) => {
                                            console.log(
                                              "Error while changing status of Notification 2 "
                                            );
                                          });
                                      } else {
                                        axios
                                          .patch(
                                            `${process.env.REACT_APP_SERVER_PATH}/notifications/${notification.id}`
                                          )
                                          .then((response) => {
                                            console.log("Status Changed");
                                            getUnreadNotifications();
                                          })
                                          .catch((e) => {
                                            console.log(
                                              "Error while changing status of Notification"
                                            );
                                          });
                                      }
                                    }}
                                  >
                                    <p className="text-sm">
                                      {notification.notif_string}
                                    </p>
                                    <p className="text-xs">
                                      {moment(notification.createdAt).format(
                                        "dddd, MMM DD, YYYY hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              <p
                                className="font-bold text-sm ml-2 text-center"
                                style={{ color: Colors.blue }}
                              >
                                No unread notifications
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* notification end */}

            {/* mobile humburger icon - start */}
            <span
              className="lg:hidden mr-2"
              onClick={() => setDrawer((prev) => !prev)}
            >
              <img
                src="/menu-svg.svg"
                className="w-8 on"
                onClick={DisableNavbarScroll}
              ></img>
            </span>
            {/* mobile humburger icon - end */}

            {/* mobile menu - end */}
            {isDrawer && (
              <div
                className={`md:hidden w-screen h-screen overflow-y-auto top-0 left-0 fixed bg-white z-20`}
              >
                <div className="z-10 flex flex-col items-center w-full p-8">
                  <div className="flex justify-end w-full">
                    <span onClick={() => setDrawer((prev) => !prev)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#828282"
                        strokeWidth={2}
                        onClick={EnableNavbarScroll}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </div>

                  <div className="w-full flex flex-col items-center mt-4 text-lg">
                    <span
                      className="mt-3 w-full"
                      onClick={() => {
                        setBrowse(!browse);
                      }}
                    >
                      <span
                        className=" font-bold  cursor-pointer rounded-xl"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <div className="flex items-center justify-between p-2 text-textDark  w-full">
                          <p>Find a mentor</p>
                          <span className="ml-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </span>
                        </div>
                        {browse && (
                          <div className="flex ml-3">
                            <div className="">
                              {industry.map((i, index) => {
                                return (
                                  <div className="flex" key={index}>
                                    <div
                                      className={`${
                                        clicked === index ? " " : ""
                                      } px-4 w-full py-2 text-textDark`}
                                      onMouseEnter={() => {
                                        toggleAccordian(index);
                                      }}
                                    >
                                      <div className="flex justify-between items-center">
                                        <button
                                          className="text-left flex items-center"
                                          onClick={() => {
                                            navigate(`/mentors/${i.slug}`);
                                            // if (queryString.pathname === "/listing") {
                                            window.location.reload(true);
                                            // }
                                          }}
                                          style={{ fontSize: "0.8rem" }}
                                        >
                                          {i.name}
                                          {i.name === "Strategy Consulting" ||
                                          i.name === "Performance Coaching" ||
                                          i.name === "Asset Management" ||
                                          i.name === "Tech" ? (
                                            <span className="text-xs ml-2 p-2 text-white bg-bgAppColor rounded-full h-6 flex items-center">
                                              new
                                            </span>
                                          ) : null}
                                        </button>
                                        {i.sub && i.sub.length > 0 && (
                                          <span
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-3 w-3"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                              strokeWidth={2}
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 5l7 7-7 7"
                                              />
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {clicked === index && (
                                      <div className="relative submenu">
                                        {i.sub && i.sub.length > 0 ? (
                                          <div className="absolute top-0 left-full bg-white shadow-md p-2 mt-2 sm:w-30 md:w-20">
                                            {/* Set different widths for different screen sizes */}
                                            {i.sub.map((s) => {
                                              return (
                                                <div
                                                  className="px-4 hover:bg-bgGray"
                                                  key={s.slug}
                                                >
                                                  <div>
                                                    <button
                                                      className="py-1 text-left w-full text-gray-800"
                                                      style={{
                                                        fontSize: "0.8rem",
                                                      }}
                                                      onClick={() => {
                                                        navigate(
                                                          `/mentors/${s.slug}`,
                                                          {
                                                            state: {
                                                              industryForToggle:
                                                                i.slug,
                                                            },
                                                          }
                                                        );
                                                        window.location.reload(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {s.name}
                                                    </button>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </span>
                    </span>

                    <Link
                      to="/apply-to-become-mentor"
                      className="inline-block w-full"
                      onClick={() => setDrawer((prev) => !prev)}
                    >
                      <p
                        className="mt-2 w-full font-bold text-textDark rounded-xl p-2 "
                        style={{ fontSize: "0.8rem" }}
                      >
                        Become a mentor
                      </p>
                    </Link>

                    <span
                      className="mt-1 w-full  items-center"
                      onClick={() => {
                        setAbout(!about);
                      }}
                    >
                      <div
                        className=" font-bold text-textDark cursor-pointer rounded-xl p-2 "
                        style={{ fontSize: "0.8rem" }}
                      >
                        <div className="flex justify-between font-bold  py-1 items-center ">
                          <p className=" ">About</p>
                          <span className="ml-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </span>
                        </div>

                        {about && (
                          <div className="relative ml-3">
                            <div className=" w-full z-40 bg-white rounded-2xl text-black py-3">
                              <div className="w-full px-4 my-2 text-textDark">
                                <Link to="/about-us">
                                  <button className="py-1 text-left w-full">
                                    About us
                                  </button>
                                </Link>
                              </div>
                              <div className="w-full px-4 my-2 text-textDark">
                                <Link to="/how-it-works">
                                  <button className="py-1 text-left w-full">
                                    How it works
                                  </button>
                                </Link>
                              </div>
                              <div className="w-full px-4 hover:bg-bgGray text-textDark">
                                <Link to="/what-our-mentor-do">
                                  <button className="py-1 text-left w-full">
                                    What our mentors do
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </span>

                    {/*  <Link to="/course" className="inline-block w-full">
                      <p className="mt-2 w-full font-bold text-textDark rounded-xl p-2 " style={{ fontSize: "0.8rem" }}>
                        Training Courses
                      </p>
                    </Link>
                    */}
                    {/* <Link
                      to="/private-equity-bundle"
                      className="inline-block w-full"
                      onClick={() => setDrawer((prev) => !prev)}
                    >
                      <p
                        className="mt-2 w-full font-bold text-textDark rounded-xl p-2 "
                        style={{ fontSize: "0.8rem" }}
                      >
                        Private Equity Bundle
                      </p>
                    </Link> */}

                    <span
                      className="mt-1 w-full  items-center"
                      onClick={() => {
                        setMore(!more);
                      }}
                    >
                      <div
                        className=" font-bold text-textDark cursor-pointer rounded-xl p-2 "
                        style={{ fontSize: "0.8rem" }}
                      >
                        <div className="flex justify-between font-bold  py-1 items-center ">
                          <p className=" ">More</p>
                          <span className="ml-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3 w-3"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </span>
                        </div>

                        {more && (
                          <div className="relative ml-3">
                            <div className=" w-full z-40 bg-white rounded-2xl text-black py-3">
                              <div className="w-full px-4 my-2 text-textDark">
                                <Link to="/download">
                                  <button className="py-1 text-left w-full">
                                    Download
                                  </button>
                                </Link>
                              </div>
                              <div className="w-full px-4 my-2 text-textDark">
                                <Link to="/career">
                                  <button className="py-1 text-left w-full">
                                    Careers
                                  </button>
                                </Link>
                              </div>
                              <div className="w-full px-4 my-2 text-textDark">
                                <Link to="/support">
                                  <button className="py-1 text-left w-full">
                                    Support
                                  </button>
                                </Link>
                              </div>
                              {type === "customer" && (
                                <div className="w-full px-4 my-2 text-textDark">
                                  <Link to="/invite-user">
                                    <button className="py-1 text-left w-full">
                                      Get £20 free
                                    </button>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* mobile menu - end */}

            {localStorage.getItem("cp_web_token") && (
              <div className="z-10 flex items-center ">
                <div className="w-6 h-6 md:w-10 md:h-10 rounded-full object-cover border border-borderGray mx-1 md:mx-3">
                  <img
                    src={
                      user.profile_photo ? user.profile_photo : "/avatar.png"
                    }
                    alt="Profile"
                    className="w-6 h-6 md:w-10 md:h-10 rounded-full object-cover"
                  />
                </div>
                {!dropDownOpen ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setDropDownOpen(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="md:h-3 md:w-[12px] h-3 w-[12px]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke={Colors.darkGray}
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                ) : (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setDropDownOpen(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="md:h-5 md:w-[12px] h-4 w-[12px]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke={Colors.darkGray}
                      strokeWidth={2}
                      onClick={handleToolTip()}
                      style={{ width: "12px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                    {dropDownOpen && (
                      <div className="relative">
                        <div className="py-2 absolute top-7 right-0 rounded-lg w-36 flex flex-col z-30 bg-bgGray border border-borderGray">
                          <button
                            className="text-textFullDark hover:text-black py-1"
                            onClick={() => {
                              navigate("/dashboard/home");
                              setDropDownOpen(false);
                            }}
                          >
                            Dashboard
                          </button>
                          <button
                            className="text-textFullDark hover:text-black py-1"
                            onClick={() => {
                              navigate("/dashboard/bookings");
                              setDropDownOpen(false);
                            }}
                          >
                            Bookings
                          </button>
                          <button
                            className="text-textFullDark hover:text-black py-1"
                            onClick={() => {
                              localStorage.removeItem("id");
                              localStorage.removeItem("type");
                              localStorage.removeItem("email");
                              localStorage.removeItem("signup_token");
                              localStorage.removeItem("navState");
                              localStorage.removeItem("cp_ref");
                              localStorage.removeItem("cp_web_token");
                              dispatch(RedirectTo({ url: "/" }));
                              navigate("/");
                              window.location.reload();
                              setDropDownOpen(false);
                              dispatch(FreeCallNotCompleted());
                            }}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </span>
                )}
              </div>
            )}

            <div className="flex">
              {localStorage.getItem("id") &&
              localStorage.getItem("cp_web_token") ? null : (
                <div>
                  <button
                    className="cp-login-btn sm:py-1 sm:px-2 md:py-2 md:px-4 "
                    onClick={toggleLogin}
                  >
                    Login{" "}
                  </button>
                  {/* <button style={{ backgroundColor: Colors.blue }} className="bg-blue-500 hover:bg-blue-700 text-white py-2 ml-3 px-4 border rounded-lg" onClick={toggleProfessionalSignUpFlow}>Professional Sign Up</button> */}
                  {/* <button style={{ backgroundColor: Colors.blue }} className="bg-blue-500 hover:bg-blue-700 text-white text-sm ml-2 md:ml-3 sm:py-1 sm:px-2 md:py-2 md:px-4 border rounded-lg" onClick={toggleCustomerSignUpFlow}>Sign Up </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
